import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import image from 'img/blog/updates/dashboard_segmenetation.png';

const Content = () => {
  return (
    <div>
      <p>
        I am happy to announce that from now on, filters and segments can be applied to{' '}
        <strong>dashboard insights</strong>!
      </p>
      <p>
        You can easily check what are the top Referrers, Countries, UTMs, Screen resolutions,
        Browsers, and Devices in chosen segment.
      </p>
      <ImageModal
        src={image}
        alt="Filtering and segmentation"
        title="Filters and segments can be applied to dashboard insights"
      />
      <p className="mt-4">We are also working on more useful insights on dashboard. Stay tuned!</p>
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Dashboard insights segmentation',
  url: '/blog/dashboard-filtering-and-segmentation',
  description: 'Use filters and segments on dashboard insights',
  author,
  img: image,
  date: '2019-02-05',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
